<template>
  <a-upload-dragger
    name="file"
    :accept="accept"
    :action="uploadData.upload_url"
    :data="uploadData"
    :file-list="fileList"
    :before-upload="handleBeforeUpload"
    @change="handleChange"
  >
    <p class="ant-upload-drag-icon">
      <a-icon type="inbox" />
    </p>
    <p class="ant-upload-text">
      单击或将文件拖到该区域以上传
    </p>
    <p class="ant-upload-hint">
      {{ hint }}
    </p>
  </a-upload-dragger>
</template>

<script>
import { getUploadToken } from '@/api/upload'

export default {
  name: 'UploadCustomFile',
  props: {
    // 已经上传的文件
    defaultFileList: {
      type: Array,
      default() {
        return []
      }
    },
    // 最多上传文件数量
    maxCount: {
      type: Number,
      default: 1
    },
    // 文件大小最大值(字节)
    maxSize: {
      type: Number,
      default: 10 * 1024 * 1024
    },
    // 接受上传的文件类型
    accept: {
      type: String,
      default: undefined
    },
    acceptValidateFunc: {
      type: Function,
      default: function() {
        return true
      }
    },
    // 提示内容
    hint: {
      type: String,
      default: '10MB以内'
    }
  },
  data() {
    return {
      fileList: this.defaultFileList,
      uploadData: {}
    }
  },
  created() {
    this.getUploadToken()
  },
  watch: {
    defaultFileList(val) {
      this.fileList = val
    }
  },
  methods: {
    getUploadToken() {
      getUploadToken().then(res => {
        this.uploadData = res.data
      })
    },

    triggerChange() {
      // Should provide an event to pass value to Form.
      this.$emit('change', this.fileList)
    },

    handleChange(info) {
      // 限制上传个数
      this.fileList = info.fileList.slice(-this.maxCount)
      this.triggerChange()
    },

    handleBeforeUpload(file, fileList) {
      if (file.size > this.maxSize) {
        this.$message.error(`文件大小不能超过 ${this.maxSize / 1024.0 / 1024.0} MB`)
        file.status = 'error'
        return false
      }

      // 文件类型是否合法
      if (!this.acceptValidateFunc(file)) {
        this.$message.error('文件格式不正确')
        file.status = 'error'
        return false
      }
    }
  }
}
</script>
